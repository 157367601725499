class Routes {
  constructor() {
    this.home = {
      intlId: 'app.home',
    };
    this.bookkeeping = {
      name: 'bookkeeping',
      path: `/bookkeeping/accounts`,
      intlId: 'app.accounts',
    };
    this.screenNotFound = {
      name: 'screenNotFound',
    };
    this.accessClosed = {
      name: 'accessClosed',
    };
    this.warehouse = {
      name: 'warehouse',
      path: `/warehouse/home`,
      intlId: 'app.home',
    };
    this.statistic = {
      name: 'statistic',
      path: `/statistic/home`,
      intlId: 'app.home',
    };
    this.sales = {
      name: 'salesHome',
      path: `/sales/home`,
      intlId: 'app.home',
    };
    this.newsletterslist = {
      name: 'newsletterslist',
      path: `/newsletters/home`,
      intlId: 'app.home',
    };
    this.messages = {
      name: 'messages',
      path: `/newsletters/messages`,
      intlId: 'app.messages',
      parent: this.newsletterslist,
    };
    this.segments = {
      name: 'segments',
      path: `/newsletters/segments`,
      intlId: 'app.segments',
      parent: this.newsletterslist,
    };
    this.magazine = {
      name: 'magazine',
      path: `/newsletters/magazine`,
      intlId: 'app.magazine',
      parent: this.newsletterslist,
    };
    this.documentation = {
      name: 'DocumentationSections',
      path: `/documentation/home`,
      intlId: 'app.home',
    };
    this.adddocumentationsection = {
      name: 'AddDocumentationSection',
      parent: this.documentation,
      path: `/documentation/adddocumentationsection`,
      intlId: 'app.adddocumentationsection',
    };
    this.boxes = {
      name: 'boxes',
      intlId: 'app.boxes',
      parent: this.warehouse,
      path: `/warehouse/boxes`,
    };
    this.box = {
      name: 'box',
      intlId: 'app.box',
      parent: this.boxes,
      path: `/warehouse/box`,
    };
    this.products = {
      name: 'products',
      intlId: 'app.products',
      parent: this.warehouse,
      path: `/warehouse/products`,
    };
    this.ordersstatistic = {
      name: 'ordersstatistic',
      intlId: 'app.ordersstatistic',
      parent: this.statistic,
      path: `/statistic/ordersstatistic`,
    };
    this.savedreports = {
      name: 'savedreports',
      intlId: 'app.savedreports',
      parent: this.statistic,
      path: `/statistic/savedreports`,
    };
    this.product = {
      name: 'product',
      intlId: 'app.product',
      parent: this.products,
      path: `/warehouse/product`,
    };
    this.acceptance = {
      name: 'acceptance',
      intlId: 'app.acceptance',
      parent: this.warehouse,
      path: `/warehouse/acceptance`,
    };
    this.orders = {
      name: 'orders',
      intlId: 'app.orders',
      parent: this.sales,
      path: `/sales/orders`,
    };
    this.appeals = {
      name: 'appeals',
      intlId: 'app.appeals',
      parent: this.sales,
      path: `/sales/appeals`,
    };
    this.comments = {
      name: 'comments',
      intlId: 'app.comments',
      parent: this.sales,
      path: `/sales/comments`,
    };
    this.order = {
      name: 'order',
      intlId: 'app.order',
      parent: this.orders,
      path: `/sales/order`,
    };
    this.customers = {
      name: 'customers',
      intlId: 'app.customers',
      parent: this.sales,
      path: `/sales/customers`,
    };
    this.customer = {
      name: 'customer',
      intlId: 'app.customer',
      parent: this.customers,
      path: `/sales/customer`,
    };
    this.purchases = {
      name: 'purchases',
      intlId: 'app.home',
      path: `/purchase/purchases`,
    };
    this.purchase = {
      name: 'purchase',
      intlId: 'app.purchase',
      parent: this.purchases,
      path: `/purchase/purchase`,
    };
    this.parcels = {
      name: 'parcels',
      intlId: 'app.parcels',
      parent: this.purchases,
      path: `/purchase/parcels`,
    };
    this.parcel = {
      name: 'parcel',
      intlId: 'app.parcel',
      parent: this.parcels,
      path: `/purchase/parcel`,
    };
    this.forwardings = {
      name: 'forwardings',
      intlId: 'app.forwardings',
      parent: this.purchases,
      path: `/purchase/forwardings`,
    };
    this.addforwarding = {
      name: 'addforwarding',
      intlId: 'app.addforwarding',
      parent: this.purchases,
      path: `/purchase/addforwarding`,
    };
    this.forwarding = {
      name: 'forwarding',
      intlId: 'app.forwarding',
      parent: this.forwardings,
      path: `/purchase/forwarding`,
    };
    this.login = {
      path: `/login`,
    };
    this.displacements = {
      name: 'displacements',
      intlId: 'app.displacements',
      parent: this.warehouse,
      path: `/warehouse/displacements`,
    };
    this.acceptance = {
      name: 'acceptance',
      intlId: 'app.acceptance',
      parent: this.warehouse,
      path: `/warehouse/acceptance`,
    };
    this.annul = {
      name: 'annul',
      intlId: 'app.annul',
      parent: this.warehouse,
      path: `/warehouse/annul`,
    };
    this.currencies = {
      name: 'currencies',
      intlId: 'app.currencies',
      path: `/bookkeeping/currencies`,
    };
    this.accounts = {
      name: 'accounts',
      intlId: 'app.accounts',
      path: `/bookkeeping/accounts`,
    };
    this.account = {
      name: 'account',
      intlId: 'app.account',
      parent: this.accounts,
      path: `/bookkeeping/account`,
    };
    this.currency = {
      name: 'currency',
      intlId: 'app.currency',
      parent: this.currencies,
      path: `/bookkeeping/currency`,
    };
    this.exchanges = {
      name: 'exchanges',
      intlId: 'app.exchanges',
      path: `/bookkeeping/exchanges`,
    };
    this.transactions = {
      name: 'transactions',
      intlId: 'app.transactions',
      path: `/bookkeeping/transactions`,
    };
    this.transaction = {
      name: 'transaction',
      intlId: 'app.transaction',
      parent: this.transactions,
      path: `/bookkeeping/transaction`,
    };
    this.exchange = {
      name: 'exchange',
      intlId: 'app.exchange',
      parent: this.exchanges,
      path: `/bookkeeping/exchange`,
    };
    this.settings = {
      name: 'settings',
      path: `/settings/home`,
      intlId: 'app.home',
    };
    this.boxlayouts = {
      name: 'boxlayouts',
      path: '/settings/boxlayouts',
      intlId: 'app.boxlayouts',
      parent: this.settings,
    };
    this.ordersstatuses = {
      name: 'ordersstatuses',
      path: '/settings/ordersstatuses',
      intlId: 'app.ordersstatuses',
      parent: this.settings,
    };
    this.crmstatuses = {
      name: 'crmstatuses',
      path: '/settings/ordersstatuses/crmstatuses',
      intlId: 'crm.name',
      parent: this.ordersstatuses,
    };
    this.flexibleshopstatuses = {
      name: 'flexibleshopstatuses',
      path: '/settings/ordersstatuses/flexibleshopstatuses',
      intlId: 'flexibleshop.name',
      parent: this.ordersstatuses,
    };
    this.flexibleshop = {
      name: 'flexibleshop',
      path: '/settings/ordersstatuses/flexibleshopstatuses',
      intlId: 'flexibleshop.name',
      parent: this.flexibleshopstatuses,
    };
    this.promstatuses = {
      name: 'prom',
      path: '/settings/ordersstatuses/promstatuses',
      intlId: 'prom.name',
      parent: this.ordersstatuses,
    };
    this.prom = {
      name: 'prom',
      path: '/settings/ordersstatuses/prom',
      intlId: 'prom.name',
      parent: this.promstatuses,
    };
    this.rozetkastatuses = {
      name: 'rozetka',
      path: '/settings/ordersstatuses/rozetkastatuses',
      intlId: 'rozetka.name',
      parent: this.ordersstatuses,
    };
    this.rozetka = {
      name: 'rozetka',
      path: '/settings/ordersstatuses/rozetka',
      intlId: 'rozetka.name',
      parent: this.rozetkastatuses,
    };
    this.strapistatuses = {
      name: 'strapi',
      path: '/settings/ordersstatuses/strapistatuses',
      intlId: 'strapi.name',
      parent: this.ordersstatuses,
    };
    this.strapi = {
      name: 'strapi',
      path: '/settings/ordersstatuses/strapi',
      intlId: 'strapi.name',
      parent: this.strapistatuses,
    };
    this.users = {
      name: 'users',
      path: '/settings/users',
      intlId: 'app.users',
      parent: this.settings,
    };
    this.user = {
      name: 'user',
      path: '/settings/user',
      intlId: 'app.user',
      parent: this.users,
    };
    this.paymentmethods = {
      name: 'paymentmethods',
      path: '/settings/paymentmethods',
      intlId: 'app.paymentmethods',
      parent: this.settings,
    };
    this.paymentmethod = {
      name: 'paymentmethod',
      path: '/settings/paymentmethod',
      intlId: 'app.paymentmethod',
      parent: this.paymentmethods,
    };
    this.deliverymethods = {
      name: 'deliverymethods',
      path: '/settings/deliverymethods',
      intlId: 'app.deliverymethods',
      parent: this.settings,
    };
    this.deliverymethod = {
      name: 'deliverymethod',
      path: '/settings/deliverymethod',
      intlId: 'app.deliverymethod',
      parent: this.deliverymethods,
    };
    this.sourcesorders = {
      name: 'sourcesorders',
      path: '/settings/sourcesorders',
      intlId: 'app.sourcesorders',
      parent: this.settings,
    };
    this.sourcesorder = {
      name: 'sourcesorder',
      path: '/settings/sourcesorder',
      intlId: 'app.sourcesorder',
      parent: this.sourcesorders,
    };
    this.boxesarrangement = {
      name: 'boxesarrangement',
      path: '/settings/boxesarrangement',
      intlId: 'app.boxesarrangement',
      parent: this.settings,
    };
    this.packagingtemplates = {
      name: 'packagingtemplates',
      path: '/settings/packagingtemplates',
      intlId: 'app.packagingtemplates',
      parent: this.settings,
    };
    this.packagingtemplate = {
      name: 'packagingtemplate',
      path: '/settings/packagingtemplate',
      intlId: 'app.packagingtemplate',
      parent: this.packagingtemplates,
    };
    this.customparams = {
      name: 'customparams',
      path: '/settings/customparams',
      intlId: 'app.customparams',
      parent: this.settings,
    };
    this.customparam = {
      name: 'customparam',
      path: '/settings/customparam',
      intlId: 'app.customparam',
      parent: this.customparams,
    };
    this.reports = {
      name: 'reports',
      intlId: 'app.reports',
      parent: this.bookkeeping,
      path: `/bookkeeping/reports`,
    };
    this.singleScreens = [
      this.customer.name,
      this.order.name,
      this.product.name,
      this.box.name,
      this.parcel.name,
      this.purchase.name,
      this.displacements.name,
      this.annul.name,
      this.acceptance.name,
      this.addforwarding.name,
      this.currency.name,
      this.exchange.name,
      this.packagingtemplates.name,
      this.packagingtemplate.name,
      this.transaction.name,
      this.deliverymethod.name,
      this.paymentmethod.name,
      this.sourcesorder.name,
      this.flexibleshop.name,
      this.flexibleshopstatuses.name,
      this.crmstatuses.name,
      this.rozetkastatuses.name,
      this.rozetka.name,
      this.promstatuses.name,
      this.prom.name,
      this.strapistatuses.name,
      this.strapi.name,
      this.screenNotFound.name,
    ];
    this.searchScreens = [
      this.products.name,
      this.customers.name,
      this.exchanges.name,
      this.transactions.name,
      this.boxes.name,
      this.packagingtemplates.name,
      this.orders.name,
      this.customparams.name,
    ];
    this.filteredScreens = [
      this.orders.name,
      this.accounts.name,
      this.customers.name,
      this.exchanges.name,
      this.bookkeeping.path,
      this.transactions.name,
      this.boxes.name,
    ];
    this.choiceCurrencyScreens = [
      this.bookkeeping.path,
      this.transactions.name,
      this.products.name,
    ];
  }

  isSingleScreen(name) {
    return this.singleScreens.includes(name);
  }

  isSearchScreen(name) {
    return this.searchScreens.includes(name);
  }

  isFilteredScreen(name) {
    return this.filteredScreens.includes(name);
  }

  isChoiceCurrencyScreen(name) {
    return this.choiceCurrencyScreens.includes(name);
  }

  getCurrentRouteName(route) {
    return this[route?.toLowerCase()];
  }
}

export const ROUTES = new Routes();
