import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { TouchableOpacity, View } from 'react-native';

import { Typography } from '../Typography/Typography';
import { styles } from './styles';
import { theme } from '../../constants';

export const TabNavigation = ({
  items,
  onChange,
  children,
  initialState = 0,
  isFullWidth = true,
  mb = 0,
  disabledIndex,
  isIcon = false,
  iconIndex,
  Icon,
}) => {
  const [current, setCurrent] = useState(initialState || 0);

  const handleButtonClick = (index) => {
    setCurrent(index);
    if (typeof onChange === 'function') {
      onChange(index);
    }
  };

  const boxWidth = !isFullWidth ? '48%' : '100%';

  return (
    <>
      <View style={[styles.wrapper, { width: boxWidth, marginBottom: mb }]}>
        {items.map((item, index) => {
          const isActive = current === index;
          return (
            <TouchableOpacity
              disabled={disabledIndex === index}
              onPress={() => handleButtonClick(index)}
              // eslint-disable-next-line
              style={[styles.item, { borderBottomWidth: isActive ? 3 : 0 }]}
              key={index}
            >
              {isIcon && iconIndex === index ? <Icon /> : null}
              <Typography
                intlId={item}
                style={[
                  styles.text,
                  { color: disabledIndex === index && theme.colors.gray },
                ]}
              />
            </TouchableOpacity>
          );
        })}
      </View>
      {React.Children.map(children, (child, index) =>
        React.cloneElement(child, { index, currentIndex: current }),
      )}
    </>
  );
};

TabNavigation.Item = function TabNavigationItem({ index, currentIndex, children }) {
  if (index !== currentIndex) return null;
  return <>{children}</>;
};

TabNavigation.Item.propTypes = {
  index: PropTypes.number,
  currentIndex: PropTypes.number,
  children: PropTypes.node,
};

TabNavigation.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func,
  children: PropTypes.node,
  initialState: PropTypes.number,
  disabledIndex: PropTypes.number,
  isIcon: PropTypes.bool,
  iconIndex: PropTypes.number,
  Icon: PropTypes.node,
};
