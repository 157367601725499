import { useMutation } from '@apollo/client';
import { produce } from 'immer';

import { DATA_REQUEST_LIMIT } from '../constants';
import { useNotificationsContext, useSearchContext } from '../context';
import { CREATE_TTN } from '../graphql/mutations';
import * as MUTATIONS from '../graphql/mutations';
import * as QUERIES from '../graphql/queries';
import { GET_ORDER } from '../graphql/queries';
import useFilter from './useFilter';
import useHistory from './useHistory';
import useParams from './useParams';

export const useStockPlaceMutation = () => {
  const navigation = useHistory();
  const { showNotification } = useNotificationsContext();

  return useMutation(MUTATIONS.STOCK_PLACE_MUTATION, {
    onCompleted: () => {
      showNotification({ message: 'stockPlace.saved' });
      navigation.push('/warehouse/boxes');
    },
  });
};
export const useStockPlaceInAcceptanceMutation = () => {
  return useMutation(MUTATIONS.STOCK_PLACE_MUTATION);
};

export const useAddProductMutation = () => {
  const { showNotification } = useNotificationsContext();
  const params = useParams();
  const navigation = useHistory();

  return useMutation(MUTATIONS.PRODUCT_MUTATION, {
    onCompleted: (response) => {
      if (params.id && response?.product) {
        showNotification({ message: 'product.saved' });
      }
      if (!params.id && response?.product) {
        navigation.push(`/warehouse/product/${response?.product?.id}`);
        showNotification({ message: 'product.saved' });
      }
    },
    update(cache, { data }) {
      if (!data?.product) {
        return null;
      }
      const products = cache.readQuery({
        query: QUERIES.GET_PRODUCTS,
      });
      cache.writeQuery({
        query: QUERIES.GET_PRODUCTS,
        data: produce(products, (draftState) => {
          draftState?.products?.push(data?.product);
        }),
      });
    },
  });
};

export const useCustomerMutation = () => {
  const navigation = useHistory();
  const { showNotification } = useNotificationsContext();

  return useMutation(MUTATIONS.CUSTOMER_MUTATION, {
    onCompleted: (response) => {
      if (response?.customer) {
        showNotification({});
        navigation.push('/sales/customers');
      }
    },
    update(cache, { data }) {
      if (!data?.customer) {
        return null;
      }

      const customers = cache.readQuery({
        query: QUERIES.GET_CUSTOMERS,
      });

      cache.writeQuery({
        query: QUERIES.GET_CUSTOMERS,
        data: produce(customers, (draftState) => {
          draftState?.filteredCustomers?.push(data.customer);
        }),
      });
    },
  });
};

export const useUserMutation = () => {
  const navigation = useHistory();
  const { showNotification } = useNotificationsContext();

  return useMutation(MUTATIONS.USER_MUTATION, {
    onCompleted: (response) => {
      if (response?.user) {
        showNotification({});
        navigation.push('/settings/users');
      }
    },
  });
};

export const usePurchaseMutation = (withOnCompleted = true) => {
  const navigation = useHistory();

  const { id } = useParams();

  const { showNotification } = useNotificationsContext();

  return useMutation(MUTATIONS.ADD_PURCHASE_MUTATION, {
    onCompleted: (response) => {
      if (response?.purchase) {
        if (withOnCompleted) {
          showNotification({ message: 'purchase.saved' });
          navigation.push('/purchase/purchases');
        }
      }
    },
    update(cache, { data }) {
      if (!data?.purchase || id) {
        return null;
      }

      const cacheId = cache.identify(data?.purchase);

      cache.modify({
        fields: {
          purchases: (existingFieldData, { toReference }) => {
            return [toReference(cacheId), ...existingFieldData];
          },
        },
      });
    },
  });
};

export const usePurchaseParcelMutation = () => {
  const { id } = useParams();

  return useMutation(MUTATIONS.ADD_PURCHASE_PARCEL_MUTATION, {
    update(cache, { data }) {
      if (!data?.purchaseParcel) {
        return null;
      }

      const parcels = cache.readQuery({
        query: QUERIES.GET_PURCHASE_PARCELS_QUERY,
        variables: { offset: 0, limit: DATA_REQUEST_LIMIT },
      });

      if (parcels) {
        cache.writeQuery({
          query: QUERIES.GET_PURCHASE_PARCELS_QUERY,
          variables: { offset: 0, limit: DATA_REQUEST_LIMIT },
          data: produce(parcels, (draftState) => {
            if (!id) {
              draftState?.purchaseParcels?.rows?.unshift(data?.purchaseParcel);
            } else {
              draftState?.purchaseParcels?.rows?.map((parcel) => {
                if (+parcel?.id === +data?.purchaseParcel?.id) {
                  return data?.purchaseParcel;
                }
                return parcel;
              });
            }
          }),
        });
      }
    },
  });
};

export const usePurchaseForwardingMutation = () => {
  const { id } = useParams();

  return useMutation(MUTATIONS.ADD_PURCHASE_FORWARDING_MUTATION, {
    update(cache, { data }) {
      if (!data?.purchaseForwarding) {
        return null;
      }

      const forwardings = cache.readQuery({
        query: QUERIES.GET_PURCHASE_FORWARDINGS_QUERY,
        variables: { offset: 0, limit: DATA_REQUEST_LIMIT },
      });

      if (forwardings) {
        cache.writeQuery({
          query: QUERIES.GET_PURCHASE_FORWARDINGS_QUERY,
          variables: { offset: 0, limit: DATA_REQUEST_LIMIT },
          data: produce(forwardings, (draftState) => {
            if (!id) {
              draftState?.purchaseForwardings?.rows?.unshift(data?.purchaseForwarding);
            } else {
              draftState?.purchaseForwardings?.rows?.map((forwarding) => {
                if (+forwarding?.id === +data?.purchaseForwarding?.id) {
                  return data?.purchaseForwarding;
                }
                return forwarding;
              });
            }
          }),
        });
      }
    },
  });
};

export const useAddPurchaseForwardingMutation = () => {
  const { showNotification } = useNotificationsContext();

  return useMutation(MUTATIONS.ADD_PURCHASE_FORWARDING_MUTATION, {
    onCompleted: (response) => {
      if (response?.purchaseForwarding) {
        showNotification({ message: 'forwarding.saved' });
      }
    },
    update(cache, { data }) {
      if (!data?.purchaseForwarding) {
        return null;
      }

      const cacheId = cache.identify(data?.purchaseForwarding);

      cache.modify({
        fields: {
          purchaseForwardings: (existingFieldData, { toReference }) => {
            return {
              ...existingFieldData,
              count: existingFieldData.count + 1,
              rows: [toReference(cacheId), ...existingFieldData?.rows],
            };
          },
        },
      });
    },
  });
};

export const useAnnulMutation = () => {
  const { showNotification } = useNotificationsContext();

  return useMutation(MUTATIONS.AVAILABILITY_REMOVE_MUTATION, {
    onCompleted: () => {
      showNotification({});
    },
  });
};

export const useAcceptanceMutation = () => {
  const { showNotification } = useNotificationsContext();

  return useMutation(MUTATIONS.AVAILABILITY_ADD_MUTATION, {
    onCompleted: (response) => {
      if (response?.availability) {
        showNotification({ message: 'product.accepted' });
      }
    },
  });
};

export const useAddCurrencyMutation = () => {
  const navigation = useHistory();
  const { showNotification } = useNotificationsContext();

  return useMutation(MUTATIONS.ADD_CURRENCY_MUTATION, {
    onCompleted: (response) => {
      if (response?.currency) {
        showNotification({});
        navigation.push('/bookkeeping/currencies');
      }
    },
    update(cache, { data }) {
      if (!data?.currency) {
        return null;
      }

      const currencies = cache.readQuery({
        query: QUERIES.GET_CURRENCIES_QUERY,
      });

      cache.writeQuery({
        query: QUERIES.GET_CURRENCIES_QUERY,
        data: produce(currencies, (draftState) => {
          draftState?.currencies?.push(data.currency);
        }),
      });
    },
  });
};

export const useExchangeMutation = () => {
  const navigation = useHistory();
  const { showNotification } = useNotificationsContext();

  return useMutation(MUTATIONS.ADD_EXCHANGE_MUTATION, {
    onCompleted: (response) => {
      if (response?.exchange) {
        showNotification({});
        navigation.push('/bookkeeping/exchanges');
      }
    },
  });
};

export const useAddPackagingTemplate = () => {
  const navigation = useHistory();

  const { showNotification } = useNotificationsContext();

  const { debouncedSearchValue } = useSearchContext();

  return useMutation(MUTATIONS.ADD_PACKAGING_TEMPLATE, {
    onCompleted: (response) => {
      if (response?.packagingTemplate) {
        showNotification({
          message: 'packagingTemplate.saved',
        });
        navigation.push('/settings/packagingtemplates');
      }
    },
    update(cache, { data }) {
      if (!data?.packagingTemplate) return null;

      const packagingTemplates = cache.readQuery({
        query: QUERIES.GET_PACKAGING_TEMPLATES,
        variables: {
          search: debouncedSearchValue,
        },
      });

      const ifExist = packagingTemplates?.packagingTemplates?.find(
        (item) => item.id === data.packagingTemplate?.id,
      );

      if (!ifExist) {
        cache.writeQuery({
          query: QUERIES.GET_PACKAGING_TEMPLATES,
          variables: {
            search: debouncedSearchValue,
          },
          data: produce(packagingTemplates, (draftState) => {
            draftState?.packagingTemplates?.push(data.packagingTemplate);
          }),
        });
      }
    },
  });
};

export const useDeletePackagingTemplate = () => {
  const navigation = useHistory();

  const { showNotification } = useNotificationsContext();

  const params = useParams();

  const { debouncedSearchValue } = useSearchContext();

  return useMutation(MUTATIONS.DELETE_PACKAGING_TEMPLATE, {
    onCompleted: (response) => {
      if (response?.deletePackagingTemplate) {
        showNotification({ message: 'packagingTemplate.deleted' });
        navigation.push('/settings/packagingtemplates');
      }
    },
    update(cache) {
      const cachedPackagingTemplates = cache.readQuery({
        query: QUERIES.GET_PACKAGING_TEMPLATES,
        variables: {
          search: debouncedSearchValue,
        },
      });

      cache.writeQuery({
        query: QUERIES.GET_PACKAGING_TEMPLATES,
        variables: {
          search: debouncedSearchValue,
        },
        data: produce(cachedPackagingTemplates, (draftState) => {
          return {
            packagingTemplates: draftState?.packagingTemplates?.filter(
              (template) => +template?.id !== +params.id,
            ),
          };
        }),
      });
    },
  });
};

export const useUpdatePurchasesMutation = () => {
  const { id } = useParams();

  return useMutation(MUTATIONS.UPDATE_PURCHASES_MUTATION, {
    update(cache, { data }) {
      if (!data?.purchases) {
        return null;
      }

      const cacheId = cache.identify(data?.purchases);

      const purchasesParcelId = +data?.purchases?.[0]?.parcel?.id;

      cache.modify({
        fields: {
          purchaseParcels: (existingFieldData, { toReference }) => {
            return existingFieldData?.rows?.map((parcel) => {
              if (+parcel?.id === purchasesParcelId) {
                return {
                  ...parcel,
                  purchases: toReference(cacheId),
                };
              } else if (!id) {
                existingFieldData.rows[0].purchases = toReference(cacheId);
              }
              return parcel;
            });
          },
        },
      });

      const purchasesForwardingId = +data?.purchases?.[0]?.forwarding?.id;

      cache.modify({
        fields: {
          purchaseForwardings: (existingFieldData, { toReference }) => {
            return existingFieldData?.rows?.map((forwarding) => {
              if (+forwarding?.id === purchasesForwardingId) {
                return {
                  ...forwarding,
                  purchases: toReference(cacheId),
                };
              } else if (!id) {
                existingFieldData.rows[0].purchases = toReference(cacheId);
              }
              return forwarding;
            });
          },
        },
      });
    },
  });
};

export const useOrderMutation = (orderId) => {
  const { showNotification } = useNotificationsContext();

  const navigation = useHistory();

  return useMutation(MUTATIONS.ORDER_MUTATION, {
    onCompleted: (response) => {
      if (response?.order) {
        showNotification({ message: 'order.saved' });
        navigation.push(`/sales/order/${response.order.id}`);
      }
    },
    update(cache, { data }) {
      if (!data?.order) return null;

      const orders = cache.readQuery({ query: QUERIES.GET_ORDERS });
      cache.writeQuery({
        query: QUERIES.GET_ORDERS,
        data: produce(orders, (draftState) => {
          draftState?.orders?.push(data?.orders);
        }),
      });
    },
    refetchQueries: [
      {
        query: orderId ? GET_ORDER : undefined,
        variables: orderId ? { id: +orderId } : undefined,
      },
    ],
  });
};

export const useDeletePurchase = () => {
  const { showNotification } = useNotificationsContext();

  return useMutation(MUTATIONS.DELETE_PURCHASE, {
    onCompleted: (response) => {
      if (response?.deletePurchase) showNotification({ message: 'purchase.deleted' });
    },
  });
};

export const useAddTransactionMutation = () => {
  const navigation = useHistory();
  const { showNotification } = useNotificationsContext();
  const { filters } = useFilter();
  const { debouncedSearchValue } = useSearchContext();

  return useMutation(MUTATIONS.ADD_TRANSACTION_MUTATION, {
    onCompleted: (response) => {
      if (response?.transaction) {
        showNotification({ message: 'transaction.saved' });
        navigation.push('/bookkeeping/transactions');
      }
    },
    update(cache, { data }) {
      if (!data?.transaction) {
        return null;
      }

      const transactions = cache.readQuery({
        query: QUERIES.GET_TRANSACTIONS_QUERY,
        variables: {
          offset: 0,
          limit: DATA_REQUEST_LIMIT,
          search: debouncedSearchValue,
          order: filters?.direction || 'desc',
          orderBy: filters?.field || 'datetime',
          filters: {
            dateFrom: filters?.dateFrom || '',
            dateTo: filters?.dateTo || '',
            typeIds: filters?.typeIds,
          },
        },
      });

      cache.writeQuery({
        query: QUERIES.GET_TRANSACTIONS_QUERY,
        variables: {
          offset: 0,
          limit: DATA_REQUEST_LIMIT,
          search: debouncedSearchValue,
          order: filters?.direction || 'desc',
          orderBy: filters?.field || 'datetime',
          filters: {
            dateFrom: filters?.dateFrom || '',
            dateTo: filters?.dateTo || '',
            typeIds: filters?.typeIds,
          },
        },
        data: produce(transactions, (draftState) => {
          draftState?.transactions?.push(data.transaction);
        }),
      });
    },
  });
};

export const useDeleteTransactionMutation = () => {
  const navigation = useHistory();
  const { showNotification } = useNotificationsContext();
  const { filters } = useFilter();
  const { debouncedSearchValue } = useSearchContext();

  return useMutation(MUTATIONS.DELETE_TRANSACTION_MUTATION, {
    onCompleted: (response) => {
      if (response?.deleteTransaction) {
        showNotification({ message: 'transaction.deleted' });
        navigation.push('/bookkeeping/transactions');
      }
    },
    update(cache) {
      const transactions = cache.readQuery({
        query: QUERIES.GET_TRANSACTIONS_QUERY,
        variables: {
          offset: 0,
          limit: DATA_REQUEST_LIMIT,
          search: debouncedSearchValue,
          order: filters?.direction || 'desc',
          orderBy: filters?.field || 'datetime',
          filters: {
            dateFrom: filters?.dateFrom || '',
            dateTo: filters?.dateTo || '',
            typeIds: filters?.typeIds,
          },
        },
      });

      cache.writeQuery({
        query: QUERIES.GET_TRANSACTIONS_QUERY,
        variables: {
          offset: 0,
          limit: DATA_REQUEST_LIMIT,
          search: debouncedSearchValue,
          order: filters?.direction || 'desc',
          orderBy: filters?.field || 'datetime',
          filters: {
            dateFrom: filters?.dateFrom || '',
            dateTo: filters?.dateTo || '',
            typeIds: filters?.typeIds,
          },
        },
        data: produce(transactions, (draftState) => {
          //I don't understand how it works
          draftState?.transactions?.push('');
        }),
      });
    },
  });
};

export const useDisplacementsMutation = () => {
  const { showNotification } = useNotificationsContext();

  return useMutation(MUTATIONS.AVAILABILITY_MOVE_MUTATION, {
    onCompleted: () => {
      showNotification({});
    },
  });
};

export const useAddFormattedPhoto = (photo) => {
  const { showNotification } = useNotificationsContext();
  return useMutation(MUTATIONS.ADD_FORMATTED_PHOTO, {
    onCompleted: (data) => {
      const url = data.formattedPhoto.pathWithTime;
      photo.current.url = url;
      showNotification({ message: 'formattedPhoto.uploaded' });
    },
  });
};

export const useCreateBankAccountMutation = () => {
  const navigation = useHistory();

  const { showNotification } = useNotificationsContext();

  return useMutation(MUTATIONS.CREATE_BANK_ACCOUNT, {
    onCompleted: () => {
      showNotification({ message: 'account.saved' });
      navigation.goBack();
    },
    refetchQueries: [{ query: QUERIES.GET_REPORTS_QUERY }],
  });
};

export const useDeleteBankAccountMutation = () => {
  const navigation = useHistory();

  const { showNotification } = useNotificationsContext();

  return useMutation(MUTATIONS.DELETE_BANK_ACCOUNT, {
    onCompleted: () => {
      showNotification({ message: 'account.deleted' });
      navigation.goBack();
    },
    refetchQueries: [{ query: QUERIES.GET_REPORTS_QUERY }],
  });
};

export const useCreateTTNMutation = () => {
  return useMutation(CREATE_TTN, {
    refetchQueries: [QUERIES.GET_ORDER],
  });
};

export const useCreatePurchaseSources = () => {
  const navigation = useHistory();

  const { showNotification } = useNotificationsContext();

  return useMutation(MUTATIONS.CREATE_PURCHASE_SOURCE, {
    onCompleted: () => {
      showNotification({ message: 'sourcesOrder.saved' });
      navigation.goBack();
    },
    refetchQueries: { query: QUERIES.GET_PURCHASE_SOURCES_QUERY, variables: {} },
  });
};

export const useCreateShippingType = () => {
  const navigation = useHistory();

  const { id } = useParams();

  const { showNotification } = useNotificationsContext();

  return useMutation(MUTATIONS.CREATE_SHIPPING_TYPE, {
    onCompleted: () => {
      showNotification({ message: 'shippingType.saved' });
      navigation.goBack();
    },
    update(cache, { data }) {
      if (!data?.shippingType || id) {
        return null;
      }
      const shippingTypes = cache.readQuery({
        query: QUERIES.GET_SHIPPING_TYPES,
        variables: {},
      });

      cache.writeQuery({
        query: QUERIES.GET_SHIPPING_TYPES,
        variables: {},
        data: produce(shippingTypes, (draftState) => {
          draftState?.shippingTypes?.push(data.shippingType);
        }),
      });
    },
  });
};

export const useDeleteShippingType = () => {
  const navigation = useHistory();

  const { showNotification } = useNotificationsContext();

  return useMutation(MUTATIONS.DELETE_SHIPPING_TYPE, {
    onCompleted: () => {
      showNotification({ message: 'shippingType.deleted' });
      navigation.goBack();
    },
    update(cache) {
      const shippingTypes = cache.readQuery({
        query: QUERIES.GET_SHIPPING_TYPES,
        variables: {},
      });

      cache.writeQuery({
        query: QUERIES.GET_SHIPPING_TYPES,
        variables: {},
        data: produce(shippingTypes, (draftState) => {
          draftState?.shippingTypes?.push('');
        }),
      });
    },
  });
};

export const useCreatePaymentType = () => {
  const navigation = useHistory();

  const { id } = useParams();

  const { showNotification } = useNotificationsContext();

  return useMutation(MUTATIONS.CREATE_PAYMENT_TYPE, {
    onCompleted: () => {
      showNotification({ message: 'paymentType.saved' });
      navigation.goBack();
    },
    update(cache, { data }) {
      if (!data?.paymentType || id) {
        return null;
      }
      const paymentTypes = cache.readQuery({
        query: QUERIES.GET_PAYMENT_TYPES,
        variables: {},
      });

      cache.writeQuery({
        query: QUERIES.GET_PAYMENT_TYPES,
        variables: {},
        data: produce(paymentTypes, (draftState) => {
          draftState?.paymentTypes?.push(data.paymentType);
        }),
      });
    },
  });
};

export const useDeletePaymentType = () => {
  const navigation = useHistory();

  const { showNotification } = useNotificationsContext();

  return useMutation(MUTATIONS.DELETE_PAYMENT_TYPE, {
    onCompleted: () => {
      showNotification({ message: 'paymentType.deleted' });
      navigation.goBack();
    },
    update(cache) {
      const paymentTypes = cache.readQuery({
        query: QUERIES.GET_PAYMENT_TYPES,
        variables: {},
      });

      cache.writeQuery({
        query: QUERIES.GET_PAYMENT_TYPES,
        variables: {},
        data: produce(paymentTypes, (draftState) => {
          draftState?.paymentTypes?.push('');
        }),
      });
    },
  });
};

export const useAddStockLocation = () => {
  const { showNotification } = useNotificationsContext();

  return useMutation(MUTATIONS.ADD_STOCK_LOCATION, {
    onCompleted: () => {
      showNotification({ message: 'location.added' });
    },
    update(cache, { data }) {
      if (!data?.stockLocation) {
        return null;
      }
      const stockLocations = cache.readQuery({
        query: QUERIES.GET_STOCK_LOCATIONS,
        variables: {},
      });

      cache.writeQuery({
        query: QUERIES.GET_STOCK_LOCATIONS,
        variables: {},
        data: produce(stockLocations, (draftState) => {
          draftState?.stockLocations?.push(data.stockLocation);
        }),
      });
    },
  });
};

export const useCreatePromOrderStatusMap = () => {
  const navigation = useHistory();

  const { showNotification } = useNotificationsContext();

  return useMutation(MUTATIONS.CREATE_PROM_ORDER_STATUS_MAP, {
    onCompleted: () => {
      showNotification({ message: 'prom.statusSaved' });
      navigation.goBack();
    },
    refetchQueries: [{ query: QUERIES.GET_PROM_ORDER_STATUS_MAPS }],
  });
};

export const useUpdatePromOrderStatusMap = () => {
  const navigation = useHistory();

  const { showNotification } = useNotificationsContext();

  return useMutation(MUTATIONS.UPDATE_PROM_ORDER_STATUS_MAP, {
    onCompleted: () => {
      showNotification({ message: 'prom.statusSaved' });
      navigation.goBack();
    },
    refetchQueries: [{ query: QUERIES.GET_PROM_ORDER_STATUS_MAPS }],
  });
};

export const useDeletePromOrderStatusMap = () => {
  const navigation = useHistory();

  const { showNotification } = useNotificationsContext();

  return useMutation(MUTATIONS.DELETE_PROM_ORDER_STATUS_MAP, {
    onCompleted: () => {
      showNotification({ message: 'prom.statusDeleted' });
      navigation.goBack();
    },
    refetchQueries: [{ query: QUERIES.GET_PROM_ORDER_STATUS_MAPS }],
  });
};

export const useCreateFlexibleshopOrderStatusMap = () => {
  const navigation = useHistory();

  const { showNotification } = useNotificationsContext();

  return useMutation(MUTATIONS.CREATE_FLEXIBLESHOP_ORDER_STATUS_MAP, {
    onCompleted: () => {
      showNotification({ message: 'flexibleshop.statusSaved' });
      navigation.goBack();
    },
    refetchQueries: [{ query: QUERIES.GET_FLEXIBLESHOP_ORDER_STATUS_MAPS }],
  });
};

export const useUpdateFlexibleshopOrderStatusMap = () => {
  const navigation = useHistory();

  const { showNotification } = useNotificationsContext();

  return useMutation(MUTATIONS.UPDATE_FLEXIBLESHOP_ORDER_STATUS_MAP, {
    onCompleted: () => {
      showNotification({ message: 'flexibleshop.statusSaved' });
      navigation.goBack();
    },
    refetchQueries: [{ query: QUERIES.GET_FLEXIBLESHOP_ORDER_STATUS_MAPS }],
  });
};

export const useDeleteFlexibleshopOrderStatusMap = () => {
  const navigation = useHistory();

  const { showNotification } = useNotificationsContext();

  return useMutation(MUTATIONS.DELETE_FLEXIBLESHOP_ORDER_STATUS_MAP, {
    onCompleted: () => {
      showNotification({ message: 'flexibleshop.statusDeleted' });
      navigation.goBack();
    },
    refetchQueries: [{ query: QUERIES.GET_FLEXIBLESHOP_ORDER_STATUS_MAPS }],
  });
};

export const useCreateRozetkaOrderStatusMap = () => {
  const navigation = useHistory();

  const { showNotification } = useNotificationsContext();

  return useMutation(MUTATIONS.CREATE_ROZETKA_ORDER_STATUS_MAP, {
    onCompleted: () => {
      showNotification({ message: 'rozetka.statusSaved' });
      navigation.goBack();
    },
    refetchQueries: [{ query: QUERIES.GET_ROZETKA_ORDER_STATUS_MAPS }],
  });
};

export const useUpdateRozetkaOrderStatusMap = () => {
  const navigation = useHistory();

  const { showNotification } = useNotificationsContext();

  return useMutation(MUTATIONS.UPDATE_ROZETKA_ORDER_STATUS_MAP, {
    onCompleted: () => {
      showNotification({ message: 'rozetka.statusSaved' });
      navigation.goBack();
    },
    refetchQueries: [{ query: QUERIES.GET_ROZETKA_ORDER_STATUS_MAPS }],
  });
};

export const useDeleteRozetkaOrderStatusMap = () => {
  const navigation = useHistory();

  const { showNotification } = useNotificationsContext();

  return useMutation(MUTATIONS.DELETE_ROZETKA_ORDER_STATUS_MAP, {
    onCompleted: () => {
      showNotification({ message: 'rozetka.statusDeleted' });
      navigation.goBack();
    },
    refetchQueries: [{ query: QUERIES.GET_ROZETKA_ORDER_STATUS_MAPS }],
  });
};

export const useCreateStrapiOrderStatusMap = () => {
  const navigation = useHistory();

  const { showNotification } = useNotificationsContext();

  return useMutation(MUTATIONS.CREATE_STRAPI_ORDER_STATUS_MAP, {
    onCompleted: () => {
      showNotification({ message: 'strapi.statusSaved' });
      navigation.goBack();
    },
    refetchQueries: [{ query: QUERIES.GET_STRAPI_ORDER_STATUS_MAPS }],
  });
};

export const useUpdateStrapiOrderStatusMap = () => {
  const navigation = useHistory();

  const { showNotification } = useNotificationsContext();

  return useMutation(MUTATIONS.UPDATE_STRAPI_ORDER_STATUS_MAP, {
    onCompleted: () => {
      showNotification({ message: 'strapi.statusSaved' });
      navigation.goBack();
    },
    refetchQueries: [{ query: QUERIES.GET_STRAPI_ORDER_STATUS_MAPS }],
  });
};

export const useDeleteStrapiOrderStatusMap = () => {
  const navigation = useHistory();

  const { showNotification } = useNotificationsContext();

  return useMutation(MUTATIONS.DELETE_STRAPI_ORDER_STATUS_MAP, {
    onCompleted: () => {
      showNotification({ message: 'strapi.statusDeleted' });
      navigation.goBack();
    },
    refetchQueries: [{ query: QUERIES.GET_STRAPI_ORDER_STATUS_MAPS }],
  });
};

export const usePhotoMutation = ({ productId }) => {
  return useMutation(MUTATIONS.PHOTO_MUTATION, {
    refetchQueries: [
      {
        query: QUERIES.GET_PRODUCT_QUERY,
        variables: { id: productId },
      },
    ],
  });
};

export const useUpdateRozetkaProductPictiures = () => {
  const params = useParams();

  return useMutation(MUTATIONS.UPDATE_ROZETKA_PRODUCT_PICTURES_MUTATION, {
    refetchQueries: [
      {
        query: QUERIES.GET_ROZETKA_PRODUCT,
        variables: { internalId: Number(params.id) },
      },
      {
        query: QUERIES.GET_PRODUCT_QUERY,
        variables: { id: Number(params.id) },
      },
    ],
  });
};

export const useUpdateRozetkaProduct = () => {
  const params = useParams();

  const { showNotification } = useNotificationsContext();

  return useMutation(MUTATIONS.UPDATE_ROZETKA_PRODUCT_MUTATION, {
    onCompleted: () => {
      showNotification({ message: 'rozetkaProduct.saved' });
    },
    refetchQueries: [
      {
        query: QUERIES.GET_ROZETKA_PRODUCT,
        variables: { internalId: Number(params.id) },
      },
    ],
  });
};

export const useCustomParam = () => {
  return useMutation(MUTATIONS.CUSTOM_PARAM_MUTATION);
};

export const useDeleteCustomParam = () => {
  const navigation = useHistory();

  const { showNotification } = useNotificationsContext();

  return useMutation(MUTATIONS.DELETE_CUSTOM_PARAM_MUTATION, {
    onCompleted: () => {
      showNotification({ message: 'customparam.paramDeleted' });
      navigation.goBack();
    },
    refetchQueries: [{ query: QUERIES.GET_CUSTOM_PARAMS }],
  });
};

export const useCustomParamOption = () => {
  return useMutation(MUTATIONS.CUSTOM_PARAM_OPTION_MUTATION);
};

export const useDeleteCustomParamOption = () => {
  const params = useParams();

  const { showNotification } = useNotificationsContext();

  return useMutation(MUTATIONS.DELETE_CUSTOM_PARAM_OPTION_MUTATION, {
    onCompleted: () => {
      showNotification({ message: 'customparam.paramOptionDeleted' });
    },
    refetchQueries: [
      {
        query: QUERIES.GET_CUSTOM_PARAM,
        variables: {
          customParamId: Number(params?.id),
        },
      },
    ],
  });
};

export const useProductCustomParam = (options) => {
  return useMutation(MUTATIONS.PRODUCT_CUSTOM_PARAM_MUTATION, {
    ...options,
  });
};

export const useDeleteProductCustomParam = (options) => {
  return useMutation(MUTATIONS.DELETE_PRODUCT_CUSTOM_PARAM_MUTATION, {
    ...options,
  });
};
